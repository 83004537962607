import { Component, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams, PopoverController } from '@ionic/angular';
import { SettingService } from '../../services/setting.service';
import { FormGroup, Validators, FormBuilder, FormArray, FormControl } from '@angular/forms';


@Component({
  selector: 'app-column-popover',
  templateUrl: './column-popover.page.html',
  styleUrls: ['./column-popover.page.scss'],
})
export class ColumnPopoverPage implements OnInit {

  type: 'preregister' | 'walk_in';
  list;
  keys;

  default_columns;
  bannable_columns;
  loaded_columns;

  /**
   * The form that encompases all the other form controls.
   */
  form: FormGroup;

  constructor(
    public navParams: NavParams,
    public modalController: ModalController,
    public settingService: SettingService,
    public fb: FormBuilder,

  ) { }

  ngOnInit() {
    this.list = {
      label: "Test"
    }

    this.keys = this.navParams.get('keys');

    this.default_columns = this.navParams.get('default_columns');
    this.bannable_columns = this.navParams.get('bannable_columns');
    this.loaded_columns = this.navParams.get('loaded_columns');

    console.log(this.loaded_columns);

    console.log(this.keys)
    this.recreateForm();

  }

  public recreateForm(): void {
    if (this.keys) {
      const keyControls = this.keys.map((control) => {
        if (this.loaded_columns.indexOf(control) > -1) {
          console.log(control)
          return new FormControl(true, []);
        } else {
          return new FormControl(false, []);
        }

      });
      // console.log(keyControls);
      // console.log(this.keys)

      this.form = this.fb.group({
        keyControls: this.fb.array(keyControls),
      });
    }

  }



  resetDefault() {
    console.log(this.form.controls["keyControls"]);
    this.keys.forEach((control, index) => {
      if (this.default_columns.indexOf(control) > -1) {
        this.form.controls["keyControls"]["controls"][index].setValue(true);
      } else {
        this.form.controls["keyControls"]["controls"][index].setValue(false);
      }

    })
  }

  save() {
    console.log(this.form.value);
    var results = this.form.value.keyControls.reduce((a, v, i) => {

      if (v) {
        a.push(this.keys[i]);
      }

      return a;
    }, []);

    console.log(results);
    this.modalController.dismiss({
      'dismissed': true,
      results: results,
    });
  }

  // exec(method: string) {
  //   this.modalController.dismiss({
  //     'method': method
  //   });
  // }

  // /**
  //  * Checks if cordova is available.
  //  * @return true if cordova is available
  //  */
  // public hasCordova(): boolean {
  //   return window.hasOwnProperty('cordova');
  // }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }


}
