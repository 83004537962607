import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentSnapshotDoesNotExist, DocumentSnapshotExists, Action, DocumentSnapshot, DocumentData, QueryDocumentSnapshot, QuerySnapshot } from '@angular/fire/firestore';
import { map, take, switchMap, combineLatest, catchError, retryWhen, first } from 'rxjs/operators';
import { Observable, of, throwError, BehaviorSubject } from 'rxjs';
import { Visitor } from '../class/visitor';
import { Preregister } from '../class/preregister';
import { environment as ENV } from '../../environments/environment';
import { Events } from '@ionic/angular';
import { event_strings } from '../data/event-strings';
import { v4 as uuid } from 'uuid';

const visitorCollection = () => {
  var settings = JSON.parse(localStorage.getItem("avms-settings"));

  var path = null;
  console.log(settings);
  if (settings.agency && settings.site)
    path = `v4-visitor/${settings.agency}/sites/${settings.site}/visitors`;

  return path;
}

@Injectable({
  providedIn: 'root'
})
export class VisitorService {

  /**
  * collection of visitor reference of firebase;
  */
  // public visitorCollection: AngularFirestoreCollection<Visitor>;

  constructor(
    public afs: AngularFirestore,
    public events: Events,
  ) {
  }

  async temp() {

    var settings = JSON.parse(localStorage.getItem("avms-settings"));
    console.log(settings);

    if (settings && (!settings.agency || !settings.site)) {
      console.log("HERE IM NULL");
      return null;
    }

    this.afs.collectionGroup<object>('visitors', (ref) =>
      ref
        .where("agency", "==", settings.agency)
      // .where("site", "==", `${settings.site}`)
      // .where("visitor_date_time", ">", 0)
      // .orderBy("visitor_date_time")
      // .limit(1)
    )
      .snapshotChanges()
      .subscribe(res => {
        console.log(res);
      });

  }

  public listVisitor(): Observable<Visitor[]> {
    var settings = JSON.parse(localStorage.getItem("avms-settings"));
    console.log(settings);

    if (settings && (!settings.agency || !settings.site)) {
      console.log("HERE IM NULL");
      return null;
    }

    return this.afs.collectionGroup<object>('visitors', (ref) =>
      ref
        .where("agency", "==", settings.agency)
        .where("site", "==", `${settings.site}`)
        .where("visitor_date_time", ">", 0)
        .orderBy("visitor_date_time")
      // .limit(1)
    )
      .snapshotChanges()
      // .snapshotChanges(['added', 'modified', 'removed'])
      .pipe(
        map(actions => {
          return actions.map(a => {

            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return new Visitor().deserialize({ id, ...data });

          });
        })
      );
  }

  /**
  * Creates random visitors
  */
  public createRandomAmountVisitor(count: number): Promise<void> {

    var batch = this.afs.firestore.batch();
    for (let index = 0; index < count; index++) {

      var v = new Visitor().serialize({
        id: uuid()
      })
      console.log(v);
      var ref = this.afs.collection(visitorCollection()).doc(`${v.id}`).ref;
      batch.set(ref, { ...v })
      // ref.set({ ...v }, { merge: true });
    }

    return batch.commit();
  }

  public retrieveCount(): Observable<number> {
    var settings = JSON.parse(localStorage.getItem("avms-settings"));
    var path;

    if (settings.agency && settings.site)
      path = `v4-visitor/${settings.agency}/sites/${settings.site}/details`;
    else
      return null

    return this.afs.collection(path).doc<{ count: number }>("count")
      // return this.afs.collection<object>(path).doc('count')
      .valueChanges()
      .pipe(
        map(actions => {
          return actions.count;
        })
      );
  }

  public async minus(count: number) {
    var batch = this.afs.firestore.batch();
    var rr = [];

    await this.listVisitorLastVisitors(count).then(res => {
      console.log(res);
      var docs: QueryDocumentSnapshot<object>[] = res.docs;
      // refs.push(doc.ref);
      docs.forEach(doc => {
        // batch.delete(doc.ref);
        rr.push(doc.ref);
        console.log(doc.ref);
      });
    });

    rr.forEach(r => {
      // batch.delete(doc.ref);
      batch.delete(r);
    })

    return batch.commit();
  }

  public batchDelete(vlist: string[]) {
    var batch = this.afs.firestore.batch();

    vlist.forEach((v) => {
      var ref = this.afs.collection(visitorCollection()).doc(`${v}`).ref;
      batch.delete(ref);
    })

    return batch.commit();
  }

  public batchUpdate(vlist: string[]) {
    var jwt = localStorage.getItem("ot-avms-token");
    var settings = JSON.parse(localStorage.getItem("avms-settings"));
    var batch = this.afs.firestore.batch();
    var endpoint = `${settings.endpoint}/api/v1/visitor`;



    vlist.forEach((v) => {
      var ref = this.afs.collection(visitorCollection()).doc(`${v}`).ref;

      if (settings.tenant_id) {
        batch.update(ref, { jwt: jwt, endpoint: endpoint, tenant_id: settings.tenant_id });
      } else {
        console.log("NO TENANT ID FOUND")
        batch.update(ref, { jwt: jwt, endpoint: endpoint });
      }

    })

    return batch.commit();
  }

  public listVisitorLastVisitors(count: number): Promise<DocumentData> {
    return this.afs.firestore.collection(visitorCollection())
      .orderBy("visitor_date_time", "desc")
      .limit(count)
      .get()
    // return this.afs.collection<object>(visitorCollection())
    // .snapshotChanges()
    // // .snapshotChanges(['added', 'modified', 'removed'])
    // .pipe(
    //   map(actions => {
    //     return actions.map(a => {

    //       const data = a.payload.doc.data();
    //       const id = a.payload.doc.id;
    //       return new Visitor().deserialize({ id, ...data });

    //     });
    //   })
    // );
  }

  public checkVisitorCheckedIn(id: string) {
    return this.afs.collection(visitorCollection()).doc(`${id}`).get().toPromise();
    // console.log(v);
  }

  // public listVisitor(): Observable<Visitor[]> {
  //   console.log(visitorCollection())
  //   if (!visitorCollection()) {
  //     return null;
  //   }
  //   return this.afs.collection<object>(visitorCollection())
  //     .snapshotChanges()
  //     // .snapshotChanges(['added', 'modified', 'removed'])
  //     .pipe(
  //       map(actions => {
  //         return actions.map(a => {

  //           const data = a.payload.doc.data();
  //           const id = a.payload.doc.id;
  //           return new Visitor().deserialize({ id, ...data });

  //         });
  //       })
  //     );
  // }


  /**
  * Finds if user exists. If exists, throw error.
  */
  public createVisitor(v: Visitor | Preregister): Observable<void> {
    return this.afs.collection(visitorCollection()).doc<Visitor>(v.id)
      .snapshotChanges()
      .pipe(
        take(1),
        switchMap((snap: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<Visitor>>) => {
          // console.log(snap.payload.data());
          // console.log(snap);
          // console.log(snap.payload.exists);
          var tempVisitor: Visitor = new Visitor().deserialize(snap.payload.data());

          if (snap.payload.exists) {
            return throwError('Visitor Exists');
          }
          // return userDocs.length ? combineLatest(userDocs) : of([]);
          return this.afs.collection(visitorCollection()).doc(`${v.id}`).set({ ...v });
        }),
      )
  }

  public setVisitor(v: Visitor): Promise<void> {
    return this.afs.collection(visitorCollection()).doc(`${v.id}`).set({ ...v })
  }

  /**
  * Delete visitor, clears visitor away from firebase.
  */
  public deleteVisitor(id: string): Promise<void> {
    return this.afs.collection(visitorCollection()).doc(`${id}`).delete();
  }

  public getVisitor(id: string) {
    return this.afs.collection(visitorCollection()).doc(id).get().toPromise();
    // return this.visitorCollection.doc<Visitor>(id).get().pipe(
    //   map((res) => {
    //     console.log(res);
    //     console.log(res.data())
    //     return new Visitor().deserialize(res.data())
    //   }),
    //
    // );
  }

  getVisitorValueChanges(id) {
    return this.afs.collection(visitorCollection()).doc(id).valueChanges()
  }

  public updateTableNumberSingle(id: string, table_no: string): Promise<void> {
    return this.afs.collection(visitorCollection()).doc(id).set({ table_no: table_no }, { merge: true });
  }

  /**
  * Update
  */
  public updateVisitor(v: Visitor): Promise<void> {
    console.log(v);
    return this.afs.collection(visitorCollection()).doc(`${v.id}`).update({ ...v });
  }

  public qrFindId(id: string): Observable<object> {
    var settings = JSON.parse(localStorage.getItem("avms-settings"));
    console.log(settings);

    if (settings && (!settings.agency || !settings.site)) {
      console.log("HERE IM NULL");
      return null;
    }

    return this.afs.collectionGroup<object>('visitors', (ref) =>
      ref
        .where("agency", "==", settings.agency)
        .where("site", "==", `${settings.site}`)
        .where("id", "==", id)
      // .limit(1)
    )
      .get()
      .pipe(
        take(1),
        map((snap: QuerySnapshot<object>) => {

          var tempVisitor;

          snap.docs.forEach(d => {
            console.log(d);
            tempVisitor = d.data();
          })

          return tempVisitor;
        }),
      );
  }

  // Comment so for other project
  //
  // /**
  // * so we can subscrube the observerable.
  // */
  // public get id() {
  //   return this._id.asObservable();
  // }
  //
  // public updateUserId(data: string) {
  //   console.log("OTPServices | updateUserId : Going to reading from this visitor instead.")
  //   this._id.next(data);
  // }
  //
  // /**
  // * Update/Create visitor
  // */
  // public setVisitor(v: Visitor): Promise<void> {
  //   return this.visitorCollection.doc(`${v.id}`).set({ ...v });
  // }
  //
  // /**
  // * Update/Create visitor
  // */
  // public updateVisitor(v: Visitor): Promise<void> {
  //   console.log(v);
  //   return this.visitorCollection.doc(`${v.id}`).set({ ...v }, { merge: true });
  // }
  //
  // public setUserOtp(id: string, otp: string, slide_index: number): Promise<void> {
  //   return this.visitorCollection.doc(id).set({ user_otp: otp, verified: false, slide_index: slide_index }, { merge: true });
  // }
  //
  // /**
  // * Update/Create visitor
  // */
  // public getVisitor(id: string) {
  //
  //   return this.visitorCollection.doc<Visitor>(id).valueChanges().pipe(
  //     map((res) => {
  //       console.log(res);
  //       if (!res) {
  //         throw new Error("Visitor not found")
  //       }
  //       return new Visitor().deserialize(res)
  //     }),
  //   );
  // }
  //
  // /**
  // * Finds if user exists. If exists, throw error.
  // */
  // public createVisitor(v: Visitor): Observable<void> {
  //   return this.visitorCollection.doc<Visitor>(v.id)
  //     .snapshotChanges()
  //     .pipe(
  //       take(1),
  //       switchMap((snap: Action<DocumentSnapshotDoesNotExist | DocumentSnapshotExists<Visitor>>) => {
  //         // console.log(snap.payload.data());
  //         // console.log(snap);
  //         // console.log(snap.payload.exists);
  //         var tempVisitor: Visitor = new Visitor().deserialize(snap.payload.data());
  //
  //         if (snap.payload.exists) {
  //           return throwError('Visitor Exists');
  //         }
  //         // return userDocs.length ? combineLatest(userDocs) : of([]);
  //         return this.visitorCollection.doc(`${v.id}`).set({ ...v });
  //       }),
  //   )
  // }
  //
  // /**
  // * Delete visitor, clears visitor away from firebase.
  // */
  // public deleteVisitor(id: string): Promise<void> {
  //   return this.visitorCollection.doc(id).delete();
  // }
  //
  // /*
  //  * Return chat room meta data based on a user.
  //  */
  // public listVisitors(): Observable<Visitor[]> {
  //   return this.visitorCollection.snapshotChanges().pipe(
  //     map(actions => {
  //       return actions.map(a => {
  //         const data = a.payload.doc.data();
  //         const id = a.payload.doc.id;
  //         return new Visitor().deserialize({ id, ...data });
  //       });
  //     })
  //   );
  // }

}
