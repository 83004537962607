import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { Observable } from 'rxjs';

import {
  AngularFireStorage,
  AngularFireStorageReference,
  AngularFireUploadTask,
} from '@angular/fire/storage';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders, HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(
    public storage: AngularFireStorage,
    // public db: AngularFireDatabase
    public http: HttpClient,
  ) { }

  private basePath: string = '/uploads';
  uploadPercent;

  base64upload(agency, site, tag: string, base64String: string, format?: string) {
    const filePath = `images/${agency}/${site}/${tag}/${uuid()}`;
    const fileRef: AngularFireStorageReference = this.storage.ref(filePath);
    console.log(filePath);

    var fformat = 'image/jpeg';
    if (format) {
      fformat = format;
    }

    return fileRef.putString(base64String, 'base64', { contentType: fformat }).snapshotChanges().pipe(
      finalize(() => {
        return fileRef.getDownloadURL()
      })
    );
  }

  text(filePath, content = "") {
    // const filePath = `images/${agency}/${site}/${tag}/${uuid()}`;
    const fileRef: AngularFireStorageReference = this.storage.ref(filePath);
    console.log(filePath);

    return fileRef.putString(content).snapshotChanges().pipe(
      finalize(() => {
        return fileRef.getDownloadURL()
      })
    );
  }

  uploadFile(event) {
    console.log(event);
    const file = event;
    const filePath = `images/${uuid()}`;
    console.log(filePath);
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(filePath, file);

    // observe percentage changes
    this.uploadPercent = task.percentageChanges();
    // get notified when the download URL is available
    return task.snapshotChanges().pipe(
      finalize(() => {
        return fileRef.getDownloadURL()
      })
    )
  }

  downloadAttachment(url) {
    const ref = this.storage.ref(url);
    return ref.getDownloadURL();
  }

  downloadHttp(url) {
    return this.http.get(url);
  }
}
