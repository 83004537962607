export const bannable_columns = [
    "id",
    "location_id",
    "updated_at",
    "created_at",
    "app_version",
    "additional_field",
    "custom_fields",
    "tenant_id",
    "mode",
    "site",
    "purpose_of_visit",
    "validation_type_options",
    "agency",
    "checkout_date_time",
    "visitor_date_time"
];

export const default_columns = [
    "name",
    "time_in",
    "time_out",
    "reason_for_visit",
    "attachments"
];