import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { JwtHelperService } from "@auth0/angular-jwt";
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';
import { GoogleLoginProvider, SocialUser } from "angularx-social-login";


const helper = new JwtHelperService();

import { Storage } from '@ionic/storage';
import { AuthService as SocialLoginService } from "angularx-social-login";


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    public http: HttpClient,
    public storage: Storage,
    public angularFireAuth: AngularFireAuth,
    public socialLoginService: SocialLoginService,
  ) {
    // this.checkIsLoggedIn();
  }

  authState() {
    return this.angularFireAuth.authState.pipe(first()).toPromise();
  }

  async checkIsLoggedIn() {
    const user = await this.authState()
    if (user) {
      // do something
    } else {
      // do something else
    }
  }

  signInAnonymously() {
    this.angularFireAuth
      .auth
      .signInAnonymously()
      .then(value => {
        console.log('Success!', value);
      })
      .catch(err => {
        console.log('Something went wrong:', err.message);
      });
  }

  /*
   * ???
   */
  public login({ username, password, origin, client_id }) {

    return this.http.post(`${origin}oauth/token`, {
      username: username,
      password: password,
      grant_type: "password",
      client_id: client_id,
      client_secret: "iL7sKZy8czjsSe7KOSuMC6Ik4IOu8VUYbNZwrPKM",
      scope: "*"
    })
  }

  /*
   * ???
   */
  private signInWithGoogle(): Promise<SocialUser> {
    return this.socialLoginService.signIn(GoogleLoginProvider.PROVIDER_ID)
  }

  public async callbackWithGoogle(origin: string, tenant_id: string | number, client_id: string) {
    var s_user = await this.signInWithGoogle();
    console.log(s_user);
    return this.socialLogin(s_user.authToken, s_user.id, origin, tenant_id, client_id);
  }

  public socialLogin(access_token: string, social_id: string, origin: string, tenant_id: string | number, client_id: string) {
    return this.http.post(`${origin}api/v1/auth/login/google`, {
      access_token: access_token,
      social_id: social_id,
      tenant_id: tenant_id,
      client_id: client_id
    }).toPromise();
  }

  isAuthenticated(): boolean {
    var valid = false;
    const token = localStorage.getItem("ot-avms-token");
    if (token) {
      try {
        const decodedToken = helper.decodeToken(token);
        // const expirationDate = helper.getTokenExpirationDate(token);
        const isExpired = helper.isTokenExpired(token);
        valid = decodedToken && (decodedToken.aud === "3" || decodedToken.aud === "1") && !isExpired;

        console.log(valid);
        console.log(decodedToken);
        if (decodedToken.roles && decodedToken.roles.filter((v) => v.toLowerCase() == "guest").length > 0) {
          valid = false;
        }
      } catch (e) { }
    }

    return valid;
  }

  public logout(): void {
    // this.storage.remove('ot-avms-token');
    // localStorage.removeItem("avms-settings");
    localStorage.removeItem("ot-avms-token");
  }

  public decodeToken(token: string): object {
    return token && helper.decodeToken(token);
  }

}
