import { SiteSetting } from './site-setting';
import { GeneralSetting } from './general-setting';
import { FormSetting } from '../interfaces/form-setting';

export class AgencySetting extends GeneralSetting {

  sites: SiteSetting[];

  /**
  * Method to call to parse result from server;
  */
  deserialize?(val: any, form_type: 'walk_in_create'
    | 'walk_in_update'
    | 'visitor_create'
    | 'visitor_guest_create'
    | 'preregister_guest_create'
    | 'preregister_guest_update'
    | 'preregister_admin_create'
    | 'preregister_admin_update'
    | 'blacklist_create'
    | 'whitelist_create'
    | 'blacklist_update'
    | 'whitelist_update',
    site: number = -1,
    reduce = true): AgencySetting {

    console.log(val)

    var siteObject;
    var keys_to_delete = ["visitor_details"];

    if (reduce) {
      keys_to_delete.push("form_setting");
    }

    // if (site > -1) {
    //   keys_to_delete.push("sites")
    // }

    if (val["sites"] && site > -1)
      siteObject = val["sites"].find(o => site == o.id);

    for (let key in val) {
      if (!keys_to_delete.find((val) => val == key)) {
        if (siteObject && siteObject[key] && key != "name") {
          this[key] = siteObject[key];
        } else {
          this[key] = val[key];
        }
      }

      if (reduce && key == "form_setting") {
        // console.log(key)
        // console.log(val["form_setting"]);

        var toReduce = val["form_setting"];
        if (siteObject && siteObject["form_setting"]) {
          toReduce = siteObject["form_setting"]
        }

        var f_setting = toReduce.reduce((a, v, i) => {

          if (v["shown_in"] && v["shown_in"].indexOf(form_type) > -1) {

            var temp_v: FormSetting = { ...v };

            // console.log(temp_v.disabled_in, temp_v.disabled_in.indexOf(form_type) > -1, form_type)
            if (temp_v.disabled_in.indexOf(form_type) > -1) {
              temp_v.disabled = true;
            }

            if (temp_v.validators_overwrite && temp_v.validators_overwrite[form_type]) {
              temp_v.validators = temp_v.validators_overwrite[form_type];
            }

            delete temp_v.validators_overwrite;
            delete temp_v.disabled_in;
            delete temp_v.shown_in;
            a.push(temp_v)
          }

          return a;
        }, []);

        this.form_setting = f_setting;
      }

    }

    if (siteObject) {
      this.name = siteObject.name;
    }

    console.log(site);
    this.agency = val["name"];
    if (site > -1) {
      this.site = site;
    }

    return this;
  }
}
