import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
// import { environment as ENV } from '../environments/environment';
// import { TabsPage } from './tabs/tabs.page';

var routes: Routes = [
  { path: 'login', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'login/:agency', loadChildren: './login/login.module#LoginPageModule' },
  // { path: 'login/:agency/:site', loadChildren: './login/login.module#LoginPageModule' },
  { path: 'qr-code/:agency/:site/:id/:otp', loadChildren: './qr-code/qr-code.module#QrCodePageModule' },
  {
    path: 'preregister/new',
    loadChildren: './create-preregister/create-preregister.module#CreatePreregisterPageModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'preregister/:id',
    loadChildren: './create-preregister/create-preregister.module#CreatePreregisterPageModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'visitor/new',
    loadChildren: './create-visitor/create-visitor.module#CreateVisitorPageModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'visitor/:id',
    loadChildren: './set-visitor/set-visitor.module#SetVisitorPageModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'preregisters',
    loadChildren: './list-preregister/list-preregister.module#ListPreregisterPageModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'visitors',
    loadChildren: './list-visitor/list-visitor.module#ListVisitorPageModule',
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'visitors-v2',
  //   loadChildren: './list-visitor-v2/list-visitor-v2.module#ListVisitorV2PageModule',
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'json-builder',
    loadChildren: './json-builder/json-builder.module#JsonBuilderPageModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'visitor-history',
    loadChildren: './visitor-history/visitor-history.module#VisitorHistoryPageModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'resident',
    loadChildren: './import/import-resident/import-resident.module#ImportResidentPageModule',
    canActivate: [AuthGuard],
  },
  { path: 'gala', loadChildren: './self-preregister/self-preregister.module#SelfPreregisterPageModule' },
  { path: 'gala/:agency', loadChildren: './self-preregister/self-preregister.module#SelfPreregisterPageModule' },
  { path: 'register/:agency', loadChildren: './self-preregister-generic/self-preregister-generic.module#SelfPreregisterGenericPageModule' },
  { path: 'register/:agency/:site', loadChildren: './self-preregister-generic/self-preregister-generic.module#SelfPreregisterGenericPageModule' },
  { path: 'visit/:agency/:site', loadChildren: './self-visitor/self-visitor.module#SelfVisitorPageModule' },
  { path: 'qr/:id', loadChildren: './self-visitor/self-visitor.module#SelfVisitorPageModule' },
  {
    path: 'setting',
    loadChildren: './setting/setting.module#SettingPageModule',
    canActivate: [AuthGuard],
  },
  { path: 'printers', loadChildren: './printer-setting/printer-setting.module#PrinterSettingPageModule' },
  { path: 'self-preregister-generic', loadChildren: './self-preregister-generic/self-preregister-generic.module#SelfPreregisterGenericPageModule' },
  { path: 'generic-popover', loadChildren: './modals/generic-popover/generic-popover.module#GenericPopoverPageModule' },
  {
    path: 'import/preregister',
    loadChildren: './import/import-preregister/import-preregister.module#ImportPreregisterPageModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'access-control/:mode',
    loadChildren: './access-control/list-access-control/list-access-control.module#ListAccessControlPageModule',
    canActivate: [AuthGuard],
  },
  {
    path: 'access-control/:mode/new',
    loadChildren: './access-control/create-access-control/create-access-control.module#CreateAccessControlPageModule',
    canActivate: [AuthGuard],
  },

  {
    path: 'counter',
    loadChildren: () => import('./visitor-counter/visitor-counter.module').then(m => m.VisitorCounterPageModule)
  },

  {
    path: 'share-qrcode',
    loadChildren: () => import('./modals/share-qrcode/share-qrcode.module').then(m => m.ShareQrcodePageModule)
  },
  {
    path: '',
    redirectTo: 'visitors',
    pathMatch: 'full',
  },
  {
    path: 'permissions',
    loadChildren: () => import('./list-employee/list-employee.module').then(m => m.ListEmployeePageModule)
  },

  {
    path: 'check/:mode',
    loadChildren: () => import('./resident/check-in/check-in.module').then(m => m.CheckInPageModule)
  },

  // {
  //   path: 'passes',
  //   loadChildren: () => import('./list-passno/list-passno.module').then(m => m.ListPassnoPageModule)
  // },

  { path: '**', redirectTo: 'visitors' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
