import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { UtilityService } from '../../services/utility.service';
import { LoaderService } from '../../services/loader.service';
import { NotifyService } from '../../services/notify.service';
import { TranslateService } from '@ngx-translate/core';
import { Visitor } from '../../class/visitor';

import 'fabric';
declare let fabric: any;

@Component({
  selector: 'app-signature',
  templateUrl: './signature.page.html',
  styleUrls: ['./signature.page.scss'],
})
export class SignaturePage implements OnInit {

  img_src: string;

  link: string;
  title: string = "AVMS QR Code";
  description: string = "Visitor Code";

  visitor: Visitor;

  private canvas;
  private boundBox;
  private blank;

  constructor(
    public modalController: ModalController,
    public utilityService: UtilityService,
    public navParams: NavParams,
    public notifyService: NotifyService,
    public loaderService: LoaderService,
    public translateService: TranslateService,
  ) {

  }

  /**
  * ???
  */
  ionViewWillEnter() {
    this.visitor = this.navParams.get('visitor');
    console.log(this.visitor);

  }

  loadCanvas() {
    this.canvas = new fabric.Canvas('c');

    this.boundBox = new fabric.Rect({
      // width: 200,
      // height: 200,
      fill: 'transparent',
      stroke: '#666',
      strokeDashArray: [5, 5]
    });

    var init_width = window.innerWidth;
    if (init_width > 600) {
      init_width = 598;
    }

    console.log(init_width)

    var tempWidth = init_width - (8 * 2);
    this.canvas.setWidth(tempWidth);
    this.canvas.setHeight(tempWidth * 2 / 3);
    this.canvas.renderAll();

    this.canvas.isDrawingMode = true;
    this.canvas.selection = false;
    fabric.Object.prototype.selectable = false; // prevent drawing objects to be draggable or clickable
    this.canvas.freeDrawingBrush.width = 3; // size of the drawing brush
    this.canvas.centerObject(this.boundBox);
    this.blank = this.canvas.toDataURL();
  }


  ngOnInit() {
    this.loadCanvas();
  }

  dismissWithSignature() {
    if (this.blank != this.canvas.toDataURL()) {

      this.modalController.dismiss({
        'dismissed': true,
        signature: this.canvas.toDataURL(),
      });
    } else {


      // this.utilityService.showAlert("Error", "Please sign before proceeding!");
      this.utilityService.statusDialog("error", false, this.translateService.instant("please_sign"));
    }
  }

  dismiss() {
    this.modalController.dismiss();
  }

  clear() {
    this.canvas.clear();
  }
}
