import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { AgencySetting } from '../class/agency-setting';
import { HttpClient } from '@angular/common/http';
import { map, take, switchMap, catchError, retryWhen, shareReplay, tap, delayWhen, first } from 'rxjs/operators';
import { Observable, of, throwError, BehaviorSubject, from, combineLatest } from 'rxjs';
import { environment as ENV } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingService {

  constructor(
    public storage: Storage,
    public http: HttpClient,
  ) {
  }

  /*
* Sample data : {"message":"Successfully added visitor","data":{"otp":"129450","otp_expiry":1563475138949}}
*/
  public apiListAccessibleSites() {
    const settings = JSON.parse(localStorage.getItem("avms-settings"));
    const url = `${settings.endpoint}/api/v1/system/list-accessible-sites`;
    const token = localStorage.getItem("ot-avms-token");

    return this.http.get(url, {
      headers: { Authorization: `Bearer ${token}` }
    })

  }

  /*
  * Sample data : {"message":"Successfully added visitor","data":{"otp":"129450","otp_expiry":1563475138949}}
  */
  public firebaseAvmsSettings(agency, endpoint) {
    // const settings = JSON.parse(localStorage.getItem("avms-settings"));
    const url = `${ENV.origin}/fire/avms-settings`;
    const token = localStorage.getItem("ot-avms-token");

    console.log(url);
    console.log(token, agency, endpoint);

    return this.http.get(url, {
      headers: {
        Authorization: `Bearer ${token}`,
        'agency': agency,
        'endpoint': endpoint,
      }
    })

  }

  /**
   * last edited : 17/06/2019 1527
   * Saves the device settings
   */
  public setDeviceSettings(json: string, site) {
    console.log(new AgencySetting().deserialize(json, 'walk_in_create', site, false));
    var json_string = JSON.stringify(new AgencySetting().deserialize(json, 'walk_in_create', site, false));
    console.log(json_string);

    localStorage.setItem("avms-settings", json_string);
  }

  /**
   * gets device setting for all application
   */
  public getDeviceSettings(form_type: 'walk_in_create'
    | 'walk_in_update'
    | 'visitor_create'
    | 'preregister_guest_create'
    | 'preregister_guest_update'
    | 'preregister_admin_create'
    | 'preregister_admin_update'
    | 'blacklist_create'
    | 'whitelist_create'
    | 'blacklist_update'
    | 'whitelist_update') {
    var json = localStorage.getItem("avms-settings");
    let json_object = JSON.parse(json);
    let site = json_object.site;

    if (site) {
      site = parseInt(site);
    }

    if (site > 0) {
      return new AgencySetting().deserialize(json_object, form_type, site, true);
    } else {
      return new AgencySetting().deserialize(json_object, form_type, -1, true);
    }

  }

  /**
   * to specifically save site id.
   * @param id site code to save
   */
  saveSite(id: number): AgencySetting {
    var json = JSON.parse(localStorage.getItem("avms-settings"));
    json["site"] = id;
    localStorage.setItem("avms-settings", JSON.stringify(json));
    return json
  }

  clearSite(): void {
    var json = JSON.parse(localStorage.getItem("avms-settings"));
    delete json["site"];
    localStorage.setItem("avms-settings", JSON.stringify(json));
  }

  // saveSite(id: number): void {
  //   var json = JSON.parse(localStorage.getItem("avms-settings"));
  //   json["site"] = id;
  //   localStorage.setItem("avms-settings", JSON.stringify(json));
  // }

  saveAccessControl(site: number, mode: 'white' | 'black', enable: boolean): void {
    var json = JSON.parse(localStorage.getItem("avms-settings"));

    var site_object = json.sites.find(j => j.id == site);
    console.log(site_object);

    if (site_object) {
      if (mode == 'white') {
        site_object["access_control"] = { "whitelist": enable };
      } else {
        site_object["access_control"] = { "blacklist": enable };
      }

      console.log(json);
      localStorage.setItem("avms-settings", JSON.stringify(json));
    }
  }

  getAgencyMapping(client: string) {
    return this.http.get(`assets/mappings/${client}.json`);
  }

  /**
  * new source so that we can make changes to via using next();
  */
  private _setting = new BehaviorSubject<AgencySetting>(null);

  /**
  * so we can subscrube the observerable.
  */
  public get setting() {
    return this._setting.asObservable();
  }

  public updateSetting(setting: AgencySetting) {
    console.log("OTPServices | updateUserId : Going to reading from this visitor instead.")
    this._setting.next(setting);
  }


}
