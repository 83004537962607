import { Component, HostListener } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import { MenuController, Events } from '@ionic/angular';
import { event_strings } from './data/event-strings';

import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from './services/device.service';
import { PreregisterService } from './firestore-services/preregister.service';
import { VisitorService } from './firestore-services/visitor.service';

import { BrotherPrinterService } from './services/brother-printer.service';
import { BluetoothSerialCustomService } from './services/bluetooth-serial-custom.service';

import { menu } from './app.menu';
import { environment as ENV } from '../environments/environment';
import { SettingService } from './services/setting.service';
import { AgencySetting } from './class/agency-setting';
import { EyoyoService } from './services/eyoyo.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {
  // public appPages =
  // public menuPages = menu;

  menuPages = [];

  /**
   * Client settings
   */
  settings: AgencySetting;

  /**
   * site list mapping for fast searching.
   */
  site_list_mapping;

  /**
   * Keep track of site in application;
   */
  site: number;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public authService: AuthService,
    public router: Router,
    public menuController: MenuController,
    public events: Events,
    public translateService: TranslateService,
    public deviceService: DeviceService,
    public preregisterService: PreregisterService,
    public visitorService: VisitorService,
    public settingService: SettingService,
    private eyoyoService: EyoyoService,
    // public brotherPrinterService: BrotherPrinterService,
    // public bluetoothSerialCustomService: BluetoothSerialCustomService,
  ) {
    this.initializeApp();
    this.initializeTranslator();
    this.setClient();
    this.initSidebarReloaderEvent();
    this.authenticateFirebase();

    setTimeout(() => {
      this.menuLoad();
    }, 0)

  }

  key = "";
  timeout;

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.timeout) {
      this.timeout = setTimeout((v) => {
        this.key = "";
        this.eyoyoService.updateSetting("");
        delete this.timeout;
      }, 500);
    }


    if (event.key == "Enter") {
      this.eyoyoService.updateSetting(this.key);
    } else {
      this.key += event.key;
    }
  }

  roles: string[];
  menuLoad() {

    try {
      const token = localStorage.getItem("ot-avms-token");
      var decoded = this.authService.decodeToken(token);
      this.roles = decoded["roles"];
      if (this.roles) {
        this.roles = this.roles.map((x) => { return x.toUpperCase(); })
        console.log(this.roles);
      }

      var settings: AgencySetting = JSON.parse(localStorage.getItem("avms-settings"));
      this.events.publish(event_strings.SHOW_MENU, settings.menu_items);

    } catch (e) {
      console.log(e); //usually never comes here.
    }
  }

  checkRole(roles) {
    var intersection = roles.filter(element => this.roles.indexOf(element) > -1).length > 0;
    return intersection;
  }

  checkCordova(cordova_hide: true | undefined) {
    if (cordova_hide == undefined) {
      return true;
    }

    else if (cordova_hide) {
      //hide when cordova
      if (window.hasOwnProperty('cordova')) {
        return false;
      }

      else if (!window.hasOwnProperty('cordova')) {
        return true;
      }
    }

    else if (!cordova_hide) {
      //reverse of previous segment
      if (window.hasOwnProperty('cordova')) {
        return true;
      }

      else if (!window.hasOwnProperty('cordova')) {
        return false;
      }
    }

    else {
      return true;
    }

  }

  setClient() {
    this.refreshSite();

    if (window.hasOwnProperty('cordova') && !this.settings) {
      this.router.navigateByUrl("/setting")
      // if (window.hasOwnProperty('cordova')) {
      //   this.router.navigateByUrl("/setting")
      // }
    }
  }

  refreshSite(): void {
    try {
      this.settings = JSON.parse(localStorage.getItem("avms-settings"));
      // this.site_list_mapping = 
      this.site_list_mapping = {};
      this.settings.sites.forEach((v, i) => {
        this.site_list_mapping[v.id] = v;
      })

      console.log(this.settings);
      if (this.settings && this.settings.site) {
        this.site = this.settings.site;
      }
    } catch (e) { }
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  /**
   * Handling of changing of site for the entire application.
   */
  public changeSite(): void {
    // this.settings = new AgencySetting().deserialize(this.settings, 'walk_in_create', -1, false);
    // var site = this.settings.
    // this.settings
    if (this.site_list_mapping[this.site].logo) {
      this.settings.logo = this.site_list_mapping[this.site].logo;
    }

    if (this.site_list_mapping[this.site].standalone) {
      this.settings.standalone = this.site_list_mapping[this.site].standalone;

      if (this.site_list_mapping[this.site].name) {
        this.settings.name = this.site_list_mapping[this.site].name;
      }
    }

    this.settingService.saveSite(this.site);
    this.settingService.updateSetting(this.settings);
  }

  /*
 * last edited : 17/07/2019 1348
 * Handles click events from menu.
 */
  public handleClick(method): void {
    if (method == "logout") {
      this.logout();
    } else if (method == "downloadKioskApp") {
      window.location.href = ENV.avms_link;
    } else if (method == "downloadDashboardApp") {
      window.location.href = ENV.dashboard_link;
    } else if (method == "hidemenu") {
      console.log("HIDEMENU")
      this.router.navigateByUrl("/visitor/new");
      this.menuController.enable(false, "admin");
      // this.events.publish(event_strings.SHOW_MENU);
    }
    this.menuController.close("admin").then((res) => { console.log(res) });
  }

  /*
 * last edited : 17/07/2019 1652
 * Initializes the translation module into the project
 */
  public initializeTranslator(): void {
    this.translateService.addLangs(['en', 'zh_SG']);
    this.translateService.setDefaultLang('en');
    const browserLang = this.translateService.getBrowserLang();
    this.translateService.use(browserLang.match(/en|zh_SG/) ? browserLang : 'en');
  }


  logout() {
    this.authService.logout();
    // this.menuController.enable(false, "admin");
    this.events.publish(event_strings.SHOW_MENU);
    var origin = JSON.parse(localStorage.getItem("avms-settings"));
    this.router.navigateByUrl(`/login/${origin.uri}`);

    if (!this.hasCordova()) {
      localStorage.removeItem("avms-settings");
      delete this.site;
      delete this.settings;
      this.settingService.updateSetting(this.settings);
    }
  }

  /*
 * last edited : 16/07/2019 0937
 * Initializes the translation module into the project
 */
  public initSidebarReloaderEvent(): void {
    this.events.subscribe(event_strings.SHOW_MENU, (menuId) => {

      console.log(this.router.url);
      console.log(menuId);
      // console.log("HIDE/SHOW MENU EVENT")

      this.refreshSite();
      this.menuPages = menuId;

      if (this.menuPages && this.menuPages.length > 0) {
        this.menuController.enable(true, "admin");
      } else {
        this.menuController.enable(false, "admin");

        //this part is to force old version to logout if not massive errors no logout button.
        const token = localStorage.getItem("ot-avms-token");
        if (token) {

          if (this.router.url.toUpperCase().indexOf("QR") > 0 ||
            this.router.url.toUpperCase().indexOf("VISIT") > 0 ||
            this.router.url.toUpperCase().indexOf("REGISTER") > 0 ||
            this.router.url.toUpperCase().indexOf("LOGIN") > 0
          ) {

          } else {
            this.logout();
          }

        }

      }

      if (this.settings && this.settings.agency == "shine") {
        this.settingService.updateSetting(this.settingService.saveSite(15));
        this.site = 15;
      }

      // this.menuPages.forEach((m) => {
      //   this.menuController.enable(false, m.role);

      //   if (m.role == menuId) {
      //     this.menuController.enable(true, m.role);
      //   }
      // })

    });
  }

  authenticateFirebase() {
    this.authService.signInAnonymously();
  }

  /**
   * Checks if cordova is available.
   * @return true if cordova is available
   */
  public hasCordova(): boolean {
    return window.hasOwnProperty('cordova');
  }

}
