import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as ENV } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  constructor(
    public http: HttpClient,
  ) { }

  /*
   * ???
   */
  // public sendSms({ id, mode }) {
  //   return this.http.post(`${ENV.origin}/fireNotify/sms`, {
  //     id, mode
  //   })
  // }

  public sendEmail(agency: string,
    site: string,
    id: string,
    otp: string,
  ) {
    
    
    return this.http.post(`${ENV.origin}/fire/email`, {
      agency: agency,
      site: site,
      id: id,
      otp: otp,
    })
  }
}
