import { Visitor } from './visitor';
import * as moment from 'moment';
import { v4 as uuid } from 'uuid';

export class Preregister extends Visitor {
  /**
  * valid period visitor is allowed.
  */
  date_ranges:
    {
      start_date: string,
      end_date: string,
      start_time: string,
      end_time: string,
    }[]

  /**
  * Only server side;
  */
  preregistered_date_time: number;

  /**
  * Tidy up the object before sending to server;
  */
  serialize(val: object, settings?: object): Preregister {
    var keys_to_delete = [
      "message",
      "startend_date",
      "people_array",
      "temp_people_array",
      "temp_email",
      "temp_mobile",
      "temp_multiple_people",
      "temp_name",
      "multi_people",
      "start_date",
      "end_date",
      "start_time",
      "end_time",
    ];

    if (val["first_name"] && val["last_name"]) {
      this.name = `${val["first_name"]} ${val["last_name"]}`;
    }

    if (val["start_date"]) {
      if (typeof val["start_date"] === "string") {
        console.log("STRING")
        console.log(val["start_date"]);
        console.log(moment(val["start_date"]));
        this.date_ranges = [{
          start_date: moment(val["start_date"], "DD/MM/YYYY").format("YYYY-MM-DD"),
          end_date: moment(val["end_date"], "DD/MM/YYYY").format("YYYY-MM-DD"),
          start_time: moment(val["start_time"], "hh:mm A").format("hh:mm A"),
          end_time: moment(val["end_time"], "hh:mm A").format("hh:mm A"),
        }];
      } else {
        if (val["start_date"] && val["start_date"]["pickerType"] == "both") {
          this.date_ranges = [{
            start_date: val["start_date"].format("YYYY-MM-DD"),
            end_date: val["start_date"].format("YYYY-MM-DD"),
            start_time: val["start_date"].format("hh:mm A"),
            end_time: val["start_date"].add("1", "hours").format("hh:mm A"),
          }];
        } else {

          //nexus fix.
          if (val["start_date"]) {
            this.date_ranges = [{
              start_date: val["start_date"].format("YYYY-MM-DD"),
              end_date: val["start_date"].format("YYYY-MM-DD"),
              start_time: moment(val["start_time"], "hh:mm A").format("hh:mm A"),
              end_time: "11:59 PM",
            }];
          } else {
            this.date_ranges = [{
              start_date: val["start_date"].format("YYYY-MM-DD"),
              end_date: val["start_date"].format("YYYY-MM-DD"),
              start_time: "12:00 AM",
              end_time: "11:59 PM",
            }];
          }

        }

      }

    }

    if (val["date_ranges"] && val["date_ranges"].length > 0) {
      console.log()
      let last_date = val["date_ranges"][val["date_ranges"].length - 1];

      val["expiry"] = moment(`${last_date.end_date} ${last_date.end_time}`, "YYYY-MM-DD HH:mm A").toDate().getTime();
      console.log(val["expiry"])
    }

    for (let key in val) {
      if (!keys_to_delete.find((val) => val == key))
        this[key] = val[key];
    }
    this.id = this.email;

    if (!settings) {
      settings = JSON.parse(localStorage.getItem("avms-settings"));
    }
    this.site = settings["site"].toString();
    this.agency = settings["agency"];
    this.mode = "preregister";
    if (settings["preregister_type"] == "sms_twillio") {
      this.id = this.mobile;
    } else {
      if (!this.email) {
        this.email = `${uuid()}@noreplyvms.com`;
        this.id = this.email;
      }
    }

    if (!this.visitor_date_time) {
      this.visitor_date_time = 0;
    }

    return this;
  }

  /**
  * Method to call to parse result from server;
  */
  deserialize(res: object): Preregister {
    if (!res) {
      return undefined;
    }
    for (let key in res) {
      this[key] = res[key];
    }

    if (res["first_name"] && res["last_name"]) {
      this.name = `${res["first_name"]} ${res["last_name"]}`;
    }

    return this;
  }

  /**
  * Tidy up the object before sending to server;
  */
  serializeGala(val: object): Preregister {

    var keys_to_delete = [
      "temp_people_array",
      "people_array",
      "temp_email",
      "temp_mobile",
      "temp_multiple_people",
      "temp_name",
      "multi_people"
    ];
    for (let key in val) {
      if (!keys_to_delete.find((val) => val == key))
        this[key] = val[key];
    }

    if (val["id"]) {
      this.id = val["id"];
    } else {
      this.id = this.email;
    }

    this.reason_for_visit = "Singapore Security Industry Awards & Gala 2019";
    this.site = "14";
    this.agency = "demo";
    this.mode = "preregister";
    this.type = "visitor";

    return this;
  }

}
