import { Injectable } from '@angular/core';
import { Observable, of, throwError, BehaviorSubject, from, combineLatest } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EyoyoService {

  constructor() { }

  /**
  * new source so that we can make changes to via using next();
  */
  private _eyoyo = new BehaviorSubject<string>(null);

  /**
  * so we can subscrube the observerable.
  */
  public get eyoyo() {
    return this._eyoyo.asObservable();
  }

  public updateSetting(_eyoyo: string) {
    this._eyoyo.next(_eyoyo);
  }

}
