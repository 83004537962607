import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { MenuController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    public authService: AuthService,
    public router: Router,
    public menuController: MenuController,
  ) {

  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    console.log(route.url)
    // console.log(this.authService.isAuthenticated())
    console.log(state.url);//'candidates'

    const token = localStorage.getItem("ot-avms-token");
    const settings = localStorage.getItem("avms-settings");

    if (state.url == '/setting' && !window.hasOwnProperty('cordova')) {
      this.rerouteLogin();
      return false;
      // return true;
    } else if (state.url == '/setting' && window.hasOwnProperty('cordova')) {
      console.log("state here")
      return true;
    }
    console.log("it came here?")

    if (!settings || !this.authService.isAuthenticated()) {
      this.rerouteLogin();

      return false;
    }
    return true;
  }

  rerouteLogin() {
    var settings = localStorage.getItem("avms-settings");
    if (settings) {
      var origin = JSON.parse(settings);
      this.router.navigateByUrl(`/login/${origin.uri}`);
    } else {
      this.router.navigateByUrl(`/login`);
    }
  }

}
