import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { UtilityService } from '../../services/utility.service';
import { LoaderService } from '../../services/loader.service';
import { NotifyService } from '../../services/notify.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-share-qrcode',
  templateUrl: './share-qrcode.page.html',
  styleUrls: ['./share-qrcode.page.scss'],
})
export class ShareQrcodePage implements OnInit {

  img_src: string;

  link: string;
  title: string = "AVMS QR Code";
  description: string = "Visitor Code";

  agency; site; id; otp;

  constructor(
    public modalController: ModalController,
    public utilityService: UtilityService,
    public navParams: NavParams,
    public notifyService: NotifyService,
    public loaderService: LoaderService,
    public translateService: TranslateService,
  ) {
    this.agency = navParams.get('agency');
    this.site = navParams.get('site');
    this.id = navParams.get('id');
    this.otp = navParams.get('otp');
    this.img_src = `https://asia-east2-avms-stage.cloudfunctions.net/fire/qr-code/${this.agency}/${this.site}/${this.id}/${this.otp}`;

    this.link = `https://vms.armfort.com/qr-code/${this.agency}/${this.site}/${this.id}/${this.otp}`;

  }

  ngOnInit() {

  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  async sendEmail() {
    var loader = await this.loaderService.presentLoading();
    loader.present();

    var res = await this.notifyService.sendEmail(
      this.agency,
      this.site,
      this.id,
      this.otp,
    ).toPromise();

    console.log(res);

    loader.dismiss();

    this.utilityService.statusDialog("success", true, this.translateService.instant("success_resend_email"), () => {
      console.log("finish dialog");
    });

  }

}
