import { Attachment } from '../interfaces/attachment';
export class Visitor {
  /**
  * id needs to be the defined because the id can either be.
  * email or mobile so it becomes too complicated to handle.
  */
  id: string;

  /**
  * for stupid clients who wishes to seperate first_name and last_name
  */
  first_name: string;

  /**
  * for stupid clients who wishes to seperate first_name and last_name
  */
  last_name: string;

  /**
  * for clever clients.
  */
  name: string;

  /**
  * [primarykey]
  * one of the primary keys. mainly for preregstration.
  */
  email: string;

  /**
  * [primarykey]
  * one of the primary keys. mainly for walk_in.
  * point of contact of visitor
  */
  mobile: string;

  /**
  * [walk_in visitor only, server generated]
  * denotes what time the user has checked in.
  */
  check_in_time: number;

  /**
  * --IMPORTANT--
  * ONLY put this once u have input in all the visitor details
  */
  mode: "walk_in" | "preregister";

  /**
  * whether the visitor checking in is a visitor/contractor.
  */
  type: "visitor" | "contractor";

  /**
  * Reason why visitor is visiting this venue in the first palce.
  */
  reason_for_visit: string;

  /**
  * to cater for multiple sites per client.
  */
  site: string;

  /**
  * to cater for multiple clients.
  */
  agency: string;

  /**
  * wchich company the visitor is from.
  */
  company: string;

  /**
  * for index counting purposes.
  */
  index: number;

  /**
  * if you're hosting someone.
  */
  parent;

  /**
  * Only server side;
  * denotes what time the visitor checked in
  */
  visitor_date_time: number;

  /**
  * Time where visitor checksout
  */
  checkout_date_time: number;

  attachments: Attachment[];

  /**
  * last 4 digit of person's identification number.
  */
  person_id: string;

  /**
  * Only server side;
  * genenrated otp 6 digit.
  */
  server_otp: string;

  /**
  * extra stuff
  */
  license_plate: string;

  /** 
   * For flir.
   */
  temperature: number;

  vehicle_no?: string;
  remarks?: string;
  unit_no?: string;
  expiry: number;
  jwt?: string;
  token?: string;
  endpoint?: string;
  location?: string;
  tenant_id?: number;


  /**
  * Method to call to parse result from server;
  */
  deserialize(res: any): Visitor {
    if (!res) {
      return undefined;
    }
    for (let key in res) {
      this[key] = res[key];
    }
    if (this.first_name && this.last_name) {
      this.name = `${this.first_name} ${this.last_name}`;
    }
    return this;
  }

  generalDeserialize(res: object): Visitor {

    var additional_field = res["additional_field"];
    var res_object = { ...res };
    delete res_object["additional_field"];
    Object.assign(this, res_object);

    if (additional_field) {
      try {
        additional_field = JSON.parse(additional_field);
      } catch (e) {
        additional_field = JSON.parse(eval('`' + additional_field + '`'))
      }
    }

    this.reason_for_visit = res["purpose_of_visit"];
    this.mobile = res["contact_no"];
    this.name = res["name"];
    this.type = res["type"];
    this.site = res["location_id"];

    if (res["vehicle_no"])
      this.vehicle_no = res["vehicle_no"];

    if (res["remarks"])
      this.remarks = res["remarks"];

    if (res["purpose_of_visit"])
      this.reason_for_visit = res["purpose_of_visit"];

    if (res["time_in"]) {
      this.visitor_date_time = new Date(res["time_in"]).getTime();
    }

    if (res["time_out"]) {
      this.checkout_date_time = new Date(res["time_out"]).getTime();
    }

  
    Object.assign(this, additional_field);
    console.log(this);


    return this;
  }

  armfortDeserialize(res: object): Visitor {

    var additional_field = res["additional_field"];
    if (additional_field) {
      try {
        additional_field = JSON.parse(additional_field);
      } catch (e) {
        additional_field = JSON.parse(eval('`' + additional_field + '`'))
      }
    }

    this.reason_for_visit = res["purpose_of_visit"];
    this.mobile = res["contact_no"];
    this.name = res["name"];
    this.type = res["type"];
    this.site = res["location_id"];

    if (res["vehicle_no"])
      this.vehicle_no = res["vehicle_no"];

    if (res["remarks"])
      this.remarks = res["remarks"];

    if (res["purpose_of_visit"])
      this.reason_for_visit = res["purpose_of_visit"];

    if (res["time_in"]) {
      this.visitor_date_time = new Date(res["time_in"]).getTime();
    }

    if (res["time_out"]) {
      this.checkout_date_time = new Date(res["time_out"]).getTime();
    }

    if (additional_field) {
      if (additional_field["id"]) this.id = additional_field["id"];
      if (additional_field["first_name"]) this.first_name = additional_field["first_name"];
      if (additional_field["last_name"]) this.last_name = additional_field["last_name"];
      if (additional_field["email"]) this.email = additional_field["email"];
      if (additional_field["check_in_time"]) this.check_in_time = additional_field["check_in_time"];
      if (additional_field["mode"]) this.mode = additional_field["mode"];
      if (additional_field["agency"]) this.agency = additional_field["agency"];
      if (additional_field["company"]) this.company = additional_field["company"];
      if (additional_field["index"]) this.index = additional_field["index"];
      if (additional_field["parent"]) this.parent = additional_field["parent"];
      if (additional_field["visitor_date_time"]) this.visitor_date_time = additional_field["visitor_date_time"];
      if (additional_field["checkout_date_time"]) this.checkout_date_time = additional_field["checkout_date_time"];
      if (additional_field["attachments"]) this.attachments = additional_field["attachments"];
      if (additional_field["person_id"]) this.person_id = additional_field["person_id"];
      if (additional_field["purpose_of_visit"]) this.reason_for_visit = additional_field["reason_for_visit"];
      if (additional_field["unit_no"]) this.unit_no = additional_field["unit_no"];
      if (additional_field["location"]) this.location = additional_field["location"];
    }


    if (this.first_name && this.last_name) {
      this.name = `${this.first_name} ${this.last_name}`;
    }

    console.log(this);
    return this;
  }

  /**
  * Tidy up the object before sending to server;
  */
  serialize(val: object): Visitor {
    var keys_to_delete = [
      "message",
      "startend_date",
      "people_array",
      "temp_people_array",
      "temp_email",
      "temp_mobile",
      "temp_multiple_people",
      "temp_name",
      "multi_people"
    ];

    for (let key in val) {
      if (!keys_to_delete.find((val) => val == key))
        this[key] = val[key];
    }
    if (!this.id) {
      this.id = this.mobile;
    }

    if (this.first_name && this.last_name) {
      this.name = `${this.first_name} ${this.last_name}`;
    }

    var settings = JSON.parse(localStorage.getItem("avms-settings"));
    this.site = settings.site.toString();
    this.agency = settings.agency;
    this.mode = "walk_in";

    return this;
  }

  /**
  * Tidy up the object before sending to server;
  */
  checkin_serialize(val: object, mode: "walk_in" | "preregister"): Visitor {
    //set default value first before copying over;
    this.serialize(val);
    this.mode = mode;
    return this;
  }
}
