import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { environment as ENV } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  constructor(
    public http: HttpClient
  ) { }

  /*
  * Sample data : {"message":"Successfully added visitor","data":{"otp":"129450","otp_expiry":1563475138949}}
  */
  public apiDevice(device) {
    return this.http.post<string>(`${ENV.origin}/fire/device`, device)
      // return this.http.post<string>(`http://0.0.0.0:5000/avms-stage/us-central1/api/device`, device)
      .pipe(
        map((res) => {
          return res["data"]["device"];
        }));
  }

  public getAgency(id) {
    return this.http.get(`${ENV.origin}/fire/agency/${id}`)
  }
}
