import { Injectable } from '@angular/core';
import { AlertController, ToastController, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Preregister } from '../class/preregister';
import { Visitor } from '../class/visitor';
// import * as moment from 'moment';

import { StatusPage } from '../modals/status/status.page';
import { ImageZoomPage } from '../modals/image-zoom/image-zoom.page';
import { ShareQrcodePage } from '../modals/share-qrcode/share-qrcode.page';
import { GenericPopoverPage } from '../modals/generic-popover/generic-popover.page';
import { ColumnPopoverPage } from '../modals/column-popover/column-popover.page';
import { SignaturePage } from '../modals/signature/signature.page';
import { PopoverController } from '@ionic/angular';

import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import { bannable_columns as BANNABLE_COL, default_columns as DEFAULT_COL } from '../data/columns';

const moment = extendMoment(Moment);

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor(
    public alertController: AlertController,
    public translateService: TranslateService,
    public toastController: ToastController,
    public modalController: ModalController,
    public popoverController: PopoverController,
  ) {
  }

  public openDialog(header_key: string, message_key: string, callback?: Function): Promise<HTMLIonAlertElement> {
    var header = this.translateService.instant(header_key);
    var message = this.translateService.instant(message_key);
    var okay = this.translateService.instant('okay');

    return this.alertController.create({
      header: header,
      // subHeader: 'Subtitle',
      message: message,
      buttons: [{
        text: okay,
        cssClass: 'secondary',
        handler: () => {
          if (callback) {
            callback();
          }
        }
      }]
    });
  }

  /**
   * Shows a succes/error animation dialog.
   */
  public imageZoomDialog(v: Visitor, src: string, callback?: Function): void {
    const dimiss_timeout = 2000;
    var id, is_visitor;

    if (v) {
      id = v.id;
      is_visitor = v.mode == "walk_in";
    }

    const modal = this.modalController.create({

      component: ImageZoomPage,
      // cssClass: 'status-modal',
      backdropDismiss: false,
      componentProps: {
        'id': id,
        'is_visitor': is_visitor,
        'src': src,
      }
    });

    modal.then((m) => {
      m.present();
      m.onDidDismiss().then((data) => {
        if (callback)
          callback();
      })

    })
  }

  /**
   * Shows a succes/error animation dialog.
   * @param status Whether it is success or error.
   * @param auto_closing Boolean flag to trigger whether the dialog closes after 2s.
   * @param message to display at the bottom.
   * @param callback function to activate once dialog closes
   */
  public statusDialog(status: 'success' | 'error', auto_closing: boolean, message: string, callback?: Function): void {
    const dimiss_timeout = 2000;
    const modal = this.modalController.create({

      component: StatusPage,
      cssClass: 'status-modal',
      backdropDismiss: false,
      componentProps: {
        'status': status,
        'message': message,
        'auto_closing': auto_closing
      }
    });

    modal.then((m) => {
      m.present();

      if (auto_closing) {
        setTimeout(() => {
          m.dismiss();
          if (callback)
            callback();
        }, dimiss_timeout);
      }

      else {
        m.onDidDismiss().then((data) => {
          if (callback)
            callback();
        })
      }

    })
  }

  public openDialogWithInput(header_key: string, value: string = "", callback?: Function): Promise<HTMLIonAlertElement> {
    const header = this.translateService.instant(header_key);
    const cancel = this.translateService.instant('cancel');
    // const agency = this.translateService.instant('agency');

    const confirm = this.translateService.instant('confirm');

    return this.alertController.create({
      backdropDismiss: false,
      header: header,
      // subHeader: 'Subtitle',
      inputs: [
        {
          name: 'agency',
          type: 'text',
          value: value,
          // placeholder: table
        },
      ],
      // message: header,
      buttons: [
        {
          text: cancel,
          cssClass: 'secondary',
          role: 'cancel',
        },
        {
          text: confirm,
          cssClass: 'secondary',
          handler: (data) => {
            if (callback) {
              callback(data.agency);

            }
          }
        },

      ]
    });
  }

  public confirmationDialog(header_key: string, message_key: string, callback?: Function): Promise<HTMLIonAlertElement> {
    const header = this.translateService.instant(header_key);
    const message = this.translateService.instant(message_key);
    const okay = this.translateService.instant('okay');
    const retry = this.translateService.instant('retry');
    const mobile = this.translateService.instant('mobile');
    const cancel = this.translateService.instant('cancel');
    const confirm = this.translateService.instant('confirm');

    return this.alertController.create({
      header: header,
      // subHeader: 'Subtitle',
      message: message,
      buttons: [{
        text: confirm,
        cssClass: 'secondary',
        handler: (data) => {
          if (callback) {
            callback();
          }
        }
      },
      {
        text: cancel,
        cssClass: 'secondary',
        role: 'cancel',
      }]
    });
  }

  /**
   * Shows a confirmation dialog.
   */
  public showVDetails(visitor: Visitor, callback: Function, editCallback: Function): Promise<HTMLIonAlertElement> {

    var details = ``;
    details += `<table class="list-vms-table-details">`;
    details += `<tr><td><b>${this.translateService.instant('name')}</b></td><td>${this.returnProper(visitor.name, '-')}</td></tr>`;
    details += `<tr><td><b>${this.translateService.instant('contact')}</b></td><td>${this.returnProper(visitor.mobile, '-')}</td></tr>`;
    details += `<tr><td><b>${this.translateService.instant('email')}</b></td><td>${this.returnProper(visitor.email, '-')}</td></tr>`;

    details += `</table>`;

    var buttons = [
      {
        text: this.translateService.instant('checkout'),
        handler: data => {
          console.log(visitor)
          if (callback) {
            callback();
          }
        }
      },
      {
        text: this.translateService.instant('edit'),
        handler: data => {
          console.log(visitor)
          editCallback();
        }
      },
      {
        text: this.translateService.instant('cancel'),
        handler: data => {
          console.log('Cancel clicked');
        }
      },
    ];

    return this.alertController.create({
      header: this.translateService.instant('visitor_details'),
      message: details,
      buttons: buttons,
    });
    // prompt.present();
  }

  public showPDetails(preregister: Preregister, callback: Function): Promise<HTMLIonAlertElement> {

    var details = ``;
    details += `<table class="list-vms-table-details">`;
    details += `<tr><td><b>${this.translateService.instant('name')}</b></td><td>${this.returnProper(preregister.name, '-')}</td></tr>`;
    details += `<tr><td><b>${this.translateService.instant('contact')}</b></td><td>${this.returnProper(preregister.mobile, '-')}</td></tr>`;
    details += `<tr><td><b>${this.translateService.instant('email')}</b></td><td>${this.returnProper(preregister.email, '-')}</td></tr>`;

    details += `</table>`;
    var buttons = [
      {
        text: this.translateService.instant('checkin'),
        handler: data => {
          console.log(preregister)
          if (callback) {
            callback();
          }
        }
      },
      {
        text: this.translateService.instant('cancel'),
        role: 'cancel',
        handler: data => {
          console.log('Cancel clicked');
        }
      },
    ];

    return this.alertController.create({
      header: this.translateService.instant('preregister_details'),
      message: details,
      buttons: buttons,
    });
  }


  public presentToast(message_key: string): Promise<HTMLIonToastElement> {
    var message = this.translateService.instant(message_key);

    return this.toastController.create({
      message: message,
      duration: 3000
    });
  }

  /**
  * Returns a string "None" if the value is null, undefined or "". Returns the original string if not.
  * @returns The desired string.
  */
  public returnProper(val: string, proper_val: string): string {
    var proper = proper_val;
    if (val) {
      proper = val;
    }
    return proper;
  }

  public generateUniqueRandom(start: number, end: number, number_of_uniques: number): number[] {
    var arr = [];
    while (arr.length < number_of_uniques) {
      var r = Math.floor(Math.random() * end) + start;
      if (arr.indexOf(r) === -1) arr.push(r);
    }
    return arr;
  }


  public presentPopover(ev: any, type: 'preregister' | 'walk_in') {
    console.log("here?");
    const popover = this.popoverController.create({
      component: GenericPopoverPage,
      event: ev,
      translucent: true,
      componentProps: {
        type: type
      }
    });
    return popover;
    // return await popover.present();
  }

  public presentColumnModal(keys: string[], default_columns, bannable_columns, loaded_columns): Promise<HTMLIonModalElement> {
    console.log("here?");

    if (default_columns) {
      default_columns = default_columns.map(v => v.name);
    } else {
      default_columns = DEFAULT_COL;
    }

    if (bannable_columns) {
      bannable_columns = bannable_columns.map(v => v.name);
    } else {
      bannable_columns = BANNABLE_COL;
    }

    if (loaded_columns) {
      loaded_columns = loaded_columns.map(v => v.name);
    }

    const modal = this.modalController.create({

      component: ColumnPopoverPage,
      // cssClass: 'status-modal',
      backdropDismiss: false,
      componentProps: {
        keys: keys,
        default_columns: default_columns,
        bannable_columns: bannable_columns,
        loaded_columns: loaded_columns
      }
    });

    return modal;
    // return await popover.present();
  }

  public presentSearchPopover(ev: any) {
    console.log("here?");
    const popover = this.popoverController.create({
      component: GenericPopoverPage,
      event: ev,
      translucent: true,
      componentProps: {
        type: 'search'
      }
    });
    return popover;
    // return await popover.present();
  }

  public uniq(a: any[]): any[] {
    var seen = {};
    return a.filter(function (item) {
      return seen.hasOwnProperty(item) ? false : (seen[item] = true);
    });
  }


  public chunk(chunkSize: number, array: any[]): any[] {
    return array.reduce(function (previous, current) {
      var chunk;
      if (previous.length === 0 ||
        previous[previous.length - 1].length === chunkSize) {
        chunk = [];   // 1
        previous.push(chunk);   // 2
      }
      else {
        chunk = previous[previous.length - 1];   // 3
      }
      chunk.push(current);   // 4
      return previous;   // 5
    }, []);   // 6
  }

  public overlap(timeSegments:
    {
      start_date: string,
      end_date: string,
      start_time: string,
      end_time: string,
    }[]
  ): boolean {
    let ret = false;
    let i = 0;
    while (!ret && i < timeSegments.length - 1) {
      let seg1 = timeSegments[i];
      let seg2 = timeSegments[i + 1];
      console.log(seg1)
      console.log(seg2)
      let range1 = moment.range(new Date(`${seg1.start_date} ${seg1.start_time}`), new Date(`${seg1.end_date} ${seg1.end_time}`));
      let range2 = moment.range(new Date(`${seg2.start_date} ${seg2.start_time}`), new Date(`${seg2.end_date} ${seg2.end_time}`));

      console.log(range1, range2);
      if (range1.overlaps(range2)) {
        ret = true;
        return ret;
      }
      i++;

      return ret;
    }
  };

  checkIfValidTime(timeSegments:
    {
      start_date: string,
      end_date: string,
      start_time: string,
      end_time: string,
    }[]
    , check_daily_range: boolean): boolean {
    var ret = false;

    for (let i = 0; i < timeSegments.length; i++) {
      let r = moment.range(
        new Date(`${timeSegments[i].start_date} ${timeSegments[i].start_time}`),
        new Date(`${timeSegments[i].end_date} ${timeSegments[i].end_time}`)
      );
      console.log(r);

      // console.log(r.contains(new Date('2015-012-20 21:00:00')));
      var today = moment().format('YYYY-MM-DD');
      if (check_daily_range) {

        if (r.contains(new Date())) {
          var today = moment().format('YYYY-MM-DD');

          let rr = moment.range(
            new Date(`${today} ${timeSegments[i].start_time}`),
            new Date(`${today} ${timeSegments[i].end_time}`)
          );

          if (rr.contains(new Date())) {
            ret = true;
            break;
          }
        }

      } else {
        if (r.contains(new Date())) {
          ret = true;
          break;
        }
      }

    }

    return ret;
  }

  sortDateRanges(date_ranges:
    {
      start_date: string,
      end_date: string,
      start_time: string,
      end_time: string,
    }[]
  ) {
    date_ranges.sort((a, b) => {
      const date1 = new Date(`${a.start_date} ${a.start_time}`);
      const date2 = new Date(`${b.start_date} ${b.start_time}`);

      let comparison = 0;
      if (date1 > date2) {
        comparison = 1;
      } else if (date1 < date2) {
        comparison = -1;
      }
      return comparison;
    });

    // console.log(date_ranges)

    // return date_ranges
  }

  checkIfDuplicateExists(w): boolean {
    return new Set(w).size !== w.length
  }

  /**
 * Method that helps to build a url based on the object given.
 * @param link The base link of the url.
 * @param params The object parameters for the link.
 * @returns The url built from the method.
 */
  public urlBuilder(link: string, params: any): string {
    let parmsString = "";
    for (let x in params) {
      if (params[x]) {
        parmsString += "&" + x + "=" + params[x];
      }
    }
    if (parmsString) {
      parmsString = "?" + parmsString.substring(1);
    }
    let query = link + parmsString;
    return query;
  }

  exportToCsv(filename, rows) {
    var processRow = function (row) {
      var finalVal = '';
      for (var j = 0; j < row.length; j++) {
        var innerValue = row[j] === null ? '' : row[j].toString();
        if (row[j] instanceof Date) {
          innerValue = row[j].toLocaleString();
        };
        var result = innerValue.replace(/"/g, '""');
        if (result.search(/("|,|\n)/g) >= 0)
          result = '"' + result + '"';
        if (j > 0)
          finalVal += ',';
        finalVal += result;
      }
      return finalVal + '\n';
    };

    var csvFile = '';
    for (var i = 0; i < rows.length; i++) {
      csvFile += processRow(rows[i]);
    }

    var blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
    if (navigator.msSaveBlob) { // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      var link = document.createElement("a");
      if (link.download !== undefined) { // feature detection
        // Browsers that support HTML5 download attribute
        var url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  /**
   * 
   * @param agency Share qr code dialog pop up
   * @param site 
   * @param id 
   * @param otp 
   * @param callback 
   */
  public shareQrCodeDialog(agency: string, site: string, id: string, otp: string, callback?: Function): void {
    const dimiss_timeout = 2000;
    const modal = this.modalController.create({

      component: ShareQrcodePage,
      // cssClass: 'status-modal',
      backdropDismiss: false,
      componentProps: {
        'agency': agency,
        'site': site,
        'id': id,
        'otp': otp
      }
    });

    modal.then((m) => {
      m.present();
      m.onDidDismiss().then((data) => {
        if (callback)
          callback();
      })

    })
  }

  /**
 * 
 * @param agency Share qr code dialog pop up
 * @param site 
 * @param id 
 * @param otp 
 * @param callback 
 */
  public showSignature(v: Visitor, callback?: Function): void {
    const dimiss_timeout = 2000;
    const modal = this.modalController.create({

      component: SignaturePage,
      // cssClass: 'status-modal',
      backdropDismiss: false,
      componentProps: {
        'visitor': v,
      }
    });

    modal.then((m) => {
      m.present();
      m.onDidDismiss().then((data) => {
        if (callback) {
          if (data) {
            callback(data.data);
          } else {
            callback();
          }

        }

      })

    })
  }


}
