import { FormSetting, PassNo, ClockSetting, DateTimeSetting, SelectSetting, DateTimeArraySetting, RestrictedSelectedSetting, TagInput } from '../interfaces/form-setting';
import { Slide, SelifeSlide, TitleSlide } from '../interfaces/slide';
import { VisitorType } from '../interfaces/visitor-type';
/**
* The ? is so it is optional for site setting is optional and can over write if needed.
*/
export class GeneralSetting {
  /**
  * name of site/agency.
  */
  name: string;

  /**
  * Path to s3 to tell what is the email template for preregister.
  */
  logo?: { src: string, transparent: boolean };

  /**
  * Path to s3 to tell what is the email template for preregister.
  */
  email_template?: {
    template?: string,
    logo?: string,
    subject?: string,
    body?: string,
  };

  /**
  * Endpoint to which armfort origin points to.
  */
  endpoint?: string;

  /**
  * How you wish to validate the user.
  * none : for clients who do not wish to validate users.
  * sms : uses the internal sim card of the device to send a sms to user..
  * sms_twillio : uses twillio to send the visitor code to the user.
  * sms_complete_native : uses phone itself to verify phone number.
  * email : sends an email from mailgun.
  */
  validation_type?: 'none' | 'sms' | 'sms_twillio' | 'email' | 'sms_complete_native';

  /**
  * not used at the moment.
  * for future use.
  * client_change_slides : to support older version. by default is false (i.e server side change slide.)
  */
  validation_type_options?: { client_change_slides?: boolean };

  /**
  * At the whole of the walk in process, how u wish to confirm the registration to the user.
  * List is an extension of validation, please refer to validation_type for the rest.
  * label_print : Uses label printer to print to brother printer.
  * receipt_print : bluetooth printer to serial printer *not implemented yet*.
  */
  confirmation_type?: 'none' | 'sms' | 'sms_twillio' | 'email' | 'label_print' | 'receipt_print';

  /**
  * to support any params for validation type.
  * label_print : to choose roll type
  */
  confirmation_type_options?: { label_print?: { labelName?: 'W62RB' | 'W62' }, label_print_for_visitor_type?: ('visitor' | 'contractor')[] };

  /**
  * Default should be email.
  * sms_twillio - Email will be sent once preregister is complete.
  */
  preregister_type?: 'none' | 'sms_twillio' | 'email';

  /**
  * Timestamp when setting is first created.
  */
  created_on?: number;

  /**
  * Timestamp when setting is last edited.
  */
  updated_on?: number;

  /**
  * form settings
  */
  form_setting?: (FormSetting | PassNo | ClockSetting | DateTimeSetting | SelectSetting | RestrictedSelectedSetting | DateTimeArraySetting | TagInput)[];

  /**
  * For walk_in only.
  */
  slides?: (Slide | SelifeSlide | TitleSlide)[];

  /**
  * Types of visitors.
  */
  visitor_types?: VisitorType[];

  agency?: string;
  site?: number;
  uri?: string;
  visitor_details?: string;
  client_security_token?: string;
  auto_fill_walk_in_fields?: string[];

  /**
  * Standalone is so that so that won't redirect to giis/:number
  */
  standalone?: boolean;

  /**
  * Allows whitelist or blacklist
  */
  access_control?: {
    whitelist?: boolean,
    blacklist?: boolean,
  };

  /**
  * Allows user to keep checking in using same qr code
  */
  auto_checkout?: boolean;

  /**
   * When checkout, signature box will popup.
   * Default value should be false.
   */
  checkout_requires_signature?: boolean;

  /**
   * For dynamic dashboard settings
   */
  dashboard_visitor_history_columns?: { name: string, prop: string }[];
  dashboard_bannable_columns?: { name: string, prop: string }[];
  dashboard_search_settings?: { name: string, prop: string }[];

  menu_items?: {
    title: string,
    url?: string,
    svg?: string,
    icon?: string,
    cordova_hide?: boolean,
    roles?: string[],
    method?: string
  }[];

  tenant_id?: number;
}
