import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-status',
  templateUrl: './status.page.html',
  styleUrls: ['./status.page.scss'],
})
export class StatusPage implements OnInit {
  status: 'success' | 'error';
  message: string;
  auto_closing: boolean;

  constructor(
    public navParams: NavParams,
    public modalController: ModalController,
  ) {
    this.status = navParams.get('status');
    this.message = navParams.get('message');
    this.auto_closing = navParams.get('auto_closing');
  }

  ngOnInit() {
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

}
