import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, DocumentSnapshotDoesNotExist, DocumentSnapshotExists, Action, DocumentSnapshot } from '@angular/fire/firestore';
import { map, take, switchMap, catchError, retryWhen, shareReplay, tap, delayWhen, first } from 'rxjs/operators';
import { Observable, of, throwError, BehaviorSubject, from, combineLatest } from 'rxjs';
import { Preregister } from '../class/preregister';
import { environment as ENV } from '../../environments/environment';
import { Events } from '@ionic/angular';
import { event_strings } from '../data/event-strings';
import { VisitorService } from './visitor.service';
import * as moment from 'moment';

/**
* gets the path of the firebase preregister;
*/
const preregisterCollection = () => {
  var settings = JSON.parse(localStorage.getItem("avms-settings"));

  var path = null;
  if (settings.agency && settings.site)
    path = `/v4-preregister/${settings.agency}/sites/${settings.site}/visitors`;

  return path;
}

@Injectable({
  providedIn: 'root'
})
export class PreregisterService {

  constructor(
    public afs: AngularFirestore,
    public events: Events,
    public visitorService: VisitorService,
  ) {
  }

  public listPreregister(): Observable<Preregister[]> {

    var settings = JSON.parse(localStorage.getItem("avms-settings"));
    console.log(settings);

    if (settings && (!settings.agency || !settings.site)) {
      console.log("blank")
      return null;
    }

    return this.afs.collectionGroup<object>('visitors', (ref) =>
      ref
        .where("agency", "==", settings.agency)
        .where("site", "==", `${settings.site}`)
        .where("visitor_date_time", "==", 0)
        .where("preregistered_date_time", ">", 0)
      // .where("preregistered_date_time", ">=", 0)
    )
      .snapshotChanges()
      // .snapshotChanges(['added', 'modified', 'removed'])
      .pipe(
        map(actions => {
          return actions.map(a => {

            console.log(a);

            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return new Preregister().deserialize({ id, ...data });

          });
        })
      );
  }

  public getExpiredPreregisters() {
    var settings = JSON.parse(localStorage.getItem("avms-settings"));
    var path = `/v4-preregister`;

    this.afs.collection(path).get()
      .subscribe(res => {
        console.log(res);
      })
  }

  public importPreregister(preregister_list: Preregister[]): Promise<void> {
    var p_list: { [key: string]: Preregister } = {};
    preregister_list.forEach((p, i) => {

      let key = p.email;

      if (key.indexOf("@noreplyvms.com") > -1) {
        key = `${p.name.toLowerCase().replace(/\s/g, '_')}`
      }

      if (p_list[key]) {
        p_list[key].date_ranges = [...p_list[key].date_ranges, ...p.date_ranges];

        var last_element = p_list[key].date_ranges[p_list[key].date_ranges.length - 1];
        p_list[key].expiry = moment(`${last_element.end_date} ${last_element.end_time}`, "YYYY-MM-DD hh:mm A").toDate().getTime();
      } else {
        p_list[key] = p;

        var last_element = p_list[key].date_ranges[p_list[key].date_ranges.length - 1];
        p_list[key].expiry = moment(`${last_element.end_date} ${last_element.end_time}`, "YYYY-MM-DD hh:mm A").toDate().getTime();
      }
    });

    console.log(p_list)
    var batch = this.afs.firestore.batch();
    Object.keys(p_list).forEach((k) => {
      var ref = this.afs.collection(preregisterCollection()).doc(`${p_list[k].id}`);
      ref.set({ ...p_list[k] }, { merge: true });
    })

    return batch.commit();
  }

  public async batchDelete(plist: string[]) {
    var batch = this.afs.firestore.batch();

    plist.forEach((p) => {
      var ref = this.afs.collection(preregisterCollection()).doc(`${p}`).ref;
      batch.delete(ref);
    })

    return batch.commit();
  }

  public async preregisterFix() {
    // var batch = this.afs.firestore.batch();

    // return batch.commit();
  }

  public fixNexus() {
    var batch = this.afs.firestore.batch();
    var count = 0;

    this.afs.collectionGroup<object>('visitors', (ref) =>
      ref
      // .where("agency", "==", "nexus")
      // .where("site", "==", settings.site)
    )
      .snapshotChanges()
      // .snapshotChanges(['added', 'modified', 'removed'])
      .pipe(
        map(actions => {
          return actions.map(a => {

            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            a.payload.doc.ref
            return new Preregister().deserialize({ id, ...data, ref: a.payload.doc.ref });

          });
        })
      ).subscribe(res => {
        res.forEach(p => {
          // console.log(p);

          if (typeof p.site == "number") {
            console.log(p);
            count++;
            var site: any = p.site;
            batch.update(p["ref"], { site: site.toString() });
          }

          // if (p.preregistered_date_time > 0) {

          //   var ref = this.afs.collection(`/v4-preregister/nexus/sites/164/visitors`).doc(`${p.id}`).ref;
          //   console.log(p)
          //   batch.update(ref, { site: "164" });
          //   // var last_element = p.date_ranges[p.date_ranges.length - 1];
          //   // console.log(moment(`${last_element.end_date} ${last_element.end_time}`, "YYYY-MM-DD hh:mm A").toDate().getTime());
          //   // batch.update(ref, { expiry: moment(`${last_element.end_date} ${last_element.end_time}`, "YYYY-MM-DD hh:mm A").toDate().getTime() });
          // }

        })


      });

    setTimeout(() => {
      batch.commit().then(ress => {
        console.log(ress)
        console.log(`updated : ${count}`)
      });
    }, 10000)
  }

  public listPreregisterExpiry(): Observable<Preregister[]> {

    var settings = JSON.parse(localStorage.getItem("avms-settings"));
    console.log(settings);

    if (settings && (!settings.agency || !settings.site)) {
      return null;
    }

    return this.afs.collectionGroup<object>('visitors', (ref) =>
      ref
        .where("preregister_expiry", ">", 0)
    )
      .snapshotChanges()
      // .snapshotChanges(['added', 'modified', 'removed'])
      .pipe(
        map(actions => {
          return actions.map(a => {

            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return new Preregister().deserialize({ id, ...data });

          });
        })
      );
  }


  // public listPreregister(): Observable<Preregister[]> {
  //   if (!preregisterCollection()) {
  //     return null;
  //   }

  //   var preregisters = [];
  //   var keys = [];
  //   var joinKeys = {};

  //   return this.afs.collection(preregisterCollection()).snapshotChanges().pipe(
  //     switchMap(p => {
  //       preregisters = p;

  //       // console.log(keys);
  //       console.log(p);
  //       const keys = p.map(k => {
  //         return k.payload.doc.data()["id"];
  //       });
  //       console.log(keys)

  //       const userDocs = keys.map(u => {
  //         // console.log(u)
  //         return this.visitorService.getVisitorValueChanges(u);
  //       });

  //       console.log(userDocs)

  //       return userDocs.length ? combineLatest(userDocs) : of([]);

  //     }),
  //     map(visitorList => {
  //       // return actions.map((a, i) => {
  //       //   const data = a.payload.doc.data();
  //       //   const id = a.payload.doc.id;
  //       //   return new Preregister().deserialize({ id, ...data, index: i });
  //       // });
  //       var data = [];
  //       console.log(visitorList);
  //       visitorList.forEach((v, i) => {
  //         if (!v) {
  //           data.push(preregisters[i].payload.doc.data());
  //         }
  //       })

  //       console.log(preregisters)
  //       console.log(data);

  //       return data;
  //     })
  //   );
  // }


  /**
  * Update/Create visitor
  */
  public setPreregister(p: Preregister): Promise<void> {
    console.log(p);
    return this.afs.collection(preregisterCollection()).doc(`${p.id}`).set({ ...p }, { merge: true });
  }

  public deletePreregister(id: string): Promise<void> {
    return this.afs.collection(preregisterCollection()).doc(id).delete()
  }

  public getPreregister(id: string, settings?: { agency: string, site: number }) {
    let link;
    if (!settings) {
      link = preregisterCollection();
    } else {
      link = `/v4-preregister/${settings.agency}/sites/${settings.site}/visitors`;
    }
    return this.afs.collection(link).doc(id).get().toPromise();
  }

  /**
  * Update
  */
  public updatePreregisterApi(p: Preregister): Promise<void> {
    return this.afs.collection(preregisterCollection()).doc(`${p.id}`).update({ ...p });
  }

}

// @Injectable({
//   providedIn: 'root'
// })
// export class OtpService {
//
//   /**
//   * collection of visitor reference of firebase;
//   */
//   public otpCollection: AngularFirestoreCollection<Otp>;
//
//   /**
//   * new source so that we can make changes to via using next();
//   */
//   private _id = new BehaviorSubject<string>("");
//
//   constructor(
//     public afs: AngularFirestore,
//   ) {
//     this.otpCollection = this.afs.collection<Otp>('testing-otp');
//   }
//
//   /**
//   * so we can subscrube the observerable.
//   */
//   public get id() {
//     return this._id.asObservable();
//   }
//
//   public updateId(data: string) {
//     console.log("OTPServices | updateUserId : Going to reading from this visitor instead.")
//     this._id.next(data);
//   }
//
//   /**
//   * Update/Create visitor
//   */
//   private createOtp(o: Otp): Promise<void> {
//     return this.otpCollection.doc(`${o.id}`).set({ ...o });
//   }
//
//   /**
//   * Update/Create visitor
//   */
//   public updateOtp(o: Otp): Promise<void> {
//     console.log(o);
//     return this.otpCollection.doc(`${o.id}`).set({ ...o }, { merge: true });
//   }
//
//   public checkVisitorCheckedIn(id: string) {
//     return this.otpCollection.doc(id).get().toPromise();
//     // console.log(v);
//   }
//
//   /**
//   * Delete otp a.k.a check in. check in is handled by firebase function.
//   */
//   public deleteOtp(id: string): Promise<void> {
//     return this.otpCollection.doc(id).delete();
//   }
//
//   /**
//   * Update/Create visitor
//   */
//   public getOtp(id: string) {
//     return this.otpCollection.doc<Otp>(id).valueChanges().pipe(
//       // shareReplay(),
//       map((res) => {
//         console.log(res);
//         if (!res) {
//           throw new Error("Otp not found");
//         }
//         return new Otp().deserialize(res)
//       }),
//       retryWhen(errors => {
//         return errors
//           .pipe(
//             delayWhen(() => {
//               var o = new Otp().serialize({ mobile: id });
//               console.log(o);
//
//               const observable = from(this.createOtp(o));
//               return observable;
//             }),
//             tap(() => console.log('retrying...'))
//           );
//       })
//     )
//     // return errors
//     //   .pipe(
//     //     switchMap(c => {
//     //       console.log("here????")
//     //       var ob_list = []
//     //       for (let x in this.user_list) {
//     //         if (this.candidate_list.indexOf(this.user_list[x]) > -1) {
//     //           ob_list.push(this.userService.profile(this.user_list[x]));
//     //         } else {
//     //           ob_list.push(this.userService.getAgency(this.user_list[x]));
//     //         }
//     //
//     //       }
//     //       return forkJoin(ob_list)
//     //     }),
//     //     delayWhen((u) => {
//     //       var ob_list = []
//     //       for (let x in u) {
//     //         // console.log(u);
//     //         // console.log(new ChatUser().deserialize(u));
//     //         var a = new ChatUser().deserialize(u[x])
//     //         a.id = a.id.toString();
//     //         const observable = from(this.setUser(a));
//     //         ob_list.push(observable)
//     //       }
//     //
//     //       return zip(ob_list);
//     //     }),
//     //     tap(() => console.log('retrying...'))
//     //   );
//
//   }
//
// }
