import { environment as ENV } from './../environments/environment';
if (ENV.production) {
  // if (true) {
  window.console.log = function () { };
}
// console.log(ENV.mode);

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, FirestoreSettingsToken } from '@angular/fire/firestore';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AngularFireStorageModule, StorageBucket } from '@angular/fire/storage';
// import { AngularFireDatabaseModule } from '@angular/fire/database';

import { IonicStorageModule } from '@ionic/storage';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';

import { AngularFireAuthModule } from '@angular/fire/auth';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

import { StatusPageModule } from './modals/status/status.module';
import { ImageZoomPageModule } from './modals/image-zoom/image-zoom.module';
import { GenericPopoverPageModule } from './modals/generic-popover/generic-popover.module';
import { ColumnPopoverPageModule } from './modals/column-popover/column-popover.module';
import { ShareQrcodePageModule } from './modals/share-qrcode/share-qrcode.module';
import { SignatureModule } from './modals/signature/signature.module';

import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { AuthService as SocialLoginService } from "angularx-social-login";

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(ENV.google_login)
  }
]);

export function provideConfig() {
  return config;
}

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    // TabsPageModule,
    // TabsPageRoutingModule,
    AngularFireModule.initializeApp(ENV.firebase_config),
    // AngularFirestoreModule,
    AngularFirestoreModule.enablePersistence(),
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    BrowserAnimationsModule,
    NgxMaterialTimepickerModule,
    AngularFireStorageModule,
    StatusPageModule,
    ImageZoomPageModule,
    GenericPopoverPageModule,
    ColumnPopoverPageModule,
    AngularFireAuthModule,
    ShareQrcodePageModule,
    SignatureModule,
    // AngularFireDatabaseModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: StorageBucket, useValue: ENV.firebase_config.storageBucket },
    AndroidPermissions,
    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    SocialLoginService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
