import { Component, OnInit } from '@angular/core';
import { NavParams } from '@ionic/angular';
import { ModalController } from '@ionic/angular';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { LoaderService } from '../../services/loader.service';
import { UploadService } from '../../services/upload.service';
import { VisitorService } from '../../firestore-services/visitor.service';
import { PreregisterService } from '../../firestore-services/preregister.service';
import { UtilityService } from '../../services/utility.service';
import { Visitor } from '../../class/visitor';
import { Preregister } from '../../class/preregister';
import { TranslateService } from '@ngx-translate/core';
import { Platform } from '@ionic/angular';

@Component({
  selector: 'app-image-zoom',
  templateUrl: './image-zoom.page.html',
  styleUrls: ['./image-zoom.page.scss'],
})
export class ImageZoomPage implements OnInit {

  src: string;
  id: string;
  is_visitor: boolean;

  constructor(
    public navParams: NavParams,
    public modalController: ModalController,
    public camera: Camera,
    public loaderService: LoaderService,
    public uploadService: UploadService,
    public visitorService: VisitorService,
    public preregisterService: PreregisterService,
    public utilityService: UtilityService,
    public translateService: TranslateService,
    public platform: Platform,
  ) {
    this.src = navParams.get('src');
    this.id = navParams.get('id');
    this.is_visitor = navParams.get('is_visitor');
  }

  ngOnInit() {
  }

  dismiss() {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  /**
 * Method called when a photo is taken in this module.
 */

  takePhoto(): void {
    const options: CameraOptions = {
      quality: 20,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      mediaType: this.camera.MediaType.PICTURE,
      correctOrientation: true  //Corrects Android orientation quirks
    }

    this.camera.getPicture(options).then((imageData) => {
      // imageData is either a base64 encoded string or a file URI
      // If it's base64 (DATA_URL):
      // let base64Image = 'data:image/jpeg;base64,' + imageData;
      this.uploadImage(imageData);
      // console.log(base64Image)
    }, (err) => {
      // Handle error
    });
  }

  // currentUpload;
  uploadImage(imageData) {
    //uploads image completed

    var settings = JSON.parse(localStorage.getItem("avms-settings"));

    this.loaderService.presentLoading().then(loader => {
      loader.present();

      console.log(imageData);
      this.uploadService.base64upload(settings.agency, settings.site, 'selfie', imageData).subscribe((res) => {
        console.log(res)
        // this.currentUpload = res;
        // console.log(res.ref["location"].path)

        // var JSON.parse(this.form.value["attachments"])
        if (res.bytesTransferred >= res.totalBytes) {
          var attachments = [{ tag: "selfie", path: res.ref["location"].path }];

          if (this.is_visitor) {
            var v = new Visitor().deserialize({ id: this.id, attachments: attachments });
            this.visitorService.updateVisitor(v).then(() => {
              let base64Image = 'data:image/jpeg;base64,' + imageData;
              this.src = base64Image;
              loader.dismiss();
              this.utilityService.statusDialog("success", true, this.translateService.instant("success_change_picture"));
            });
          }

          else {
            var p = new Preregister().deserialize({ id: this.id, attachments: attachments });

            this.preregisterService.updatePreregisterApi(p).then(() => {
              let base64Image = 'data:image/jpeg;base64,' + imageData;
              this.src = base64Image;
              loader.dismiss();
              this.utilityService.statusDialog("success", true, this.translateService.instant("success_change_picture"));
            });
          }

        }
      });

    });
  }

}
