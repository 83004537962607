import { Component, OnInit } from '@angular/core';
import { NavParams, PopoverController } from '@ionic/angular';
import { SettingService } from '../../services/setting.service';

@Component({
  selector: 'app-generic-popover',
  templateUrl: './generic-popover.page.html',
  styleUrls: ['./generic-popover.page.scss'],
})
export class GenericPopoverPage implements OnInit {

  type: 'preregister' | 'walk_in';
  list;

  constructor(
    public navParams: NavParams,
    public popoverController: PopoverController,
    public settingService: SettingService
  ) { }

  ngOnInit() {
    this.type = this.navParams.get('type');
    console.log(this.type)

    if (this.type == 'preregister') {
      this.list = [
        { "label": "delete_preregister", "method": "deletePreregister" },
        { "label": "share_qr_code", "method": "shareQrCode" },
      ]
    } else if (this.type == 'walk_in') {

      var full_list = [
        // { "label": "reauthenticate", "method": "reauthenticate" },
        { "label": "share_qr_code", "method": "shareQrCode" },
      ]

      if (this.hasCordova()) {
        this.list = [
          { "label": "reprint_label", "method": "reprintLabel" },
          ...full_list,
        ]
      } else {
        this.list = full_list;
      }

    } else if (this.type == 'search') {
      var search_list = this.settingService.getDeviceSettings("walk_in_create").dashboard_search_settings;
      if (search_list) {
        this.list = search_list.map(v => {
          return { "label": v.name, "method": v.prop }
        });
      }
      // this.list = [
      //   { "label": "delete_preregister", "method": "deletePreregister" },
      //   { "label": "share_qr_code", "method": "shareQrCode" },
      // ]
    }

    console.log(this.list);
  }

  exec(method: string) {
    this.popoverController.dismiss({
      'method': method
    });
  }

  /**
   * Checks if cordova is available.
   * @return true if cordova is available
   */
  public hasCordova(): boolean {
    return window.hasOwnProperty('cordova');
  }

}
