// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  mode: "DEVELOPMENT",
  build: "mobile",
  // build: "browser",
  agency: "demo",
  site: "13",
  production: false,
  firebase_config: {
    apiKey: "AIzaSyAZNiHF8xhMX9AKC0K55pGCtHM_pePM9mY",
    authDomain: "avms-stage.firebaseapp.com",
    databaseURL: "https://avms-stage.firebaseio.com",
    projectId: "avms-stage",
    storageBucket: "avms-stage.appspot.com",
    messagingSenderId: "280574325857",
    appId: "1:280574325857:web:a1cd6e0280ef0dd9"
  },
  // firebase_config: {
  //   apiKey: "AIzaSyAoJCRbfIzv60dM5dps5Ss4MKifhlWQqG4",
  //   authDomain: "avms-live.firebaseapp.com",
  //   databaseURL: "https://avms-live.firebaseio.com",
  //   projectId: "avms-live",
  //   storageBucket: "avms-live.appspot.com",
  //   messagingSenderId: "293491172199",
  //   appId: "1:293491172199:web:4f8f15c0366c3264cc85c2",
  //   measurementId: "G-2D37968RKL"
  // },
  update_link: "https://armfort-bucket.s3-ap-southeast-1.amazonaws.com/app-update/_avms/avms_giis_dashboard.xml",
  origin: "https://asia-east2-avms-stage.cloudfunctions.net",

  avms_link: 'https://armfort-bucket.s3-ap-southeast-1.amazonaws.com/app-update/_avms/avms.apk',
  dashboard_link: 'https://armfort-bucket.s3-ap-southeast-1.amazonaws.com/app-update/_avms/dashboard.apk',
  google_login: "867395757087-s5dbi4cdo5dk85vojt0jkc3ohaq4d0es.apps.googleusercontent.com",
  safe_entry: "https://asia-east2-avms-live.cloudfunctions.net/fire/safeentry/test/",
  reCAPTCHA: "6LcZUjIbAAAAABefoV92LEWDHvpVWsxHB2cs0VfH"
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
// export const environment = {
//   mode: "PRODUCTION",
//   production: false,
//   // build: "browser",
//   agency: "demo",
//   site: "13",
//   firebase_config: {
//     apiKey: "AIzaSyAoJCRbfIzv60dM5dps5Ss4MKifhlWQqG4",
//     authDomain: "avms-live.firebaseapp.com",
//     databaseURL: "https://avms-live.firebaseio.com",
//     projectId: "avms-live",
//     storageBucket: "avms-live.appspot.com",
//     messagingSenderId: "293491172199",
//     appId: "1:293491172199:web:4f8f15c0366c3264cc85c2",
//     measurementId: "G-2D37968RKL"
//   },
//   update_link: "https://armfort-bucket.s3-ap-southeast-1.amazonaws.com/app-update/_avms/avms_giis.xml",
//   origin: "https://asia-east2-avms-live.cloudfunctions.net",
//   avms_link: 'https://armfort-bucket.s3-ap-southeast-1.amazonaws.com/app-update/_avms/testing/avms-stage.apk',
//   dashboard_link: 'https://armfort-bucket.s3-ap-southeast-1.amazonaws.com/app-update/_avms/testing/dashboard-stage.apk',
// };