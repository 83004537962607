import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  constructor(
    public loadingController: LoadingController,
  ) { }

  presentLoading() {
    return this.loadingController.create({
      message: 'Loading'
    });
    // await loading.present();
    //
    // const { role, data } = await loading.onDidDismiss();
    //
    // console.log('Loading dismissed!');
  }
}
